// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-json-page-js": () => import("./../../src/templates/json-page.js" /* webpackChunkName: "component---src-templates-json-page-js" */),
  "component---src-templates-list-choice-page-js": () => import("./../../src/templates/list-choice-page.js" /* webpackChunkName: "component---src-templates-list-choice-page-js" */),
  "component---src-templates-list-page-js": () => import("./../../src/templates/list-page.js" /* webpackChunkName: "component---src-templates-list-page-js" */),
  "component---src-templates-pdf-page-js": () => import("./../../src/templates/pdf-page.js" /* webpackChunkName: "component---src-templates-pdf-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../src/templates/post-page.js" /* webpackChunkName: "component---src-templates-post-page-js" */)
}

